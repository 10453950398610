import request from '@/utils/request'
// /loan/getLoanConfig获取贷款配置
export const getLoanConfig = data => {
  return request({
    method: 'post',
    url: '/loan/getLoanConfig',
    data
  })
}
// /loan/apply申请贷款
export const apply = data => {
  return request({
    method: 'post',
    url: '/loan/apply',
    data
  })
}
// /loan/successList滚动成功贷款列表
export const successList = data => {
  return request({
    method: 'post',
    url: '/loan/successList',
    data
  })
}
// /advertisement/get获取广告
export const getadv = data => {
  return request({
    method: 'post',
    url: '/advertisement/get',
    params: data
  })
}
// /phone/save保存电话号码
export const save = data => {
  return request({
    method: 'post',
    url: '/phone/save',
    data
  })
}
// /user/setRegistrationID设置极光推送id
export const setRegistrationID = data => {
  return request({
    method: 'post',
    url: '/user/setRegistrationID',
    params: data
  })
}
